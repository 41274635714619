import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LazyLoader from "../components/LazyLoader";
import Login from "../pages/login/Login";
import PrivateRoute from "../components/PrivateRoute";
import UploadLead from "../pages/leads/UploadLead";
import WebLead from "../pages/leads/AssignLead";

// lazy load components
const PasswordSet = lazy(() => import("../pages/login/PasswordSet"));
const AddnewUser = lazy(() => import("../pages/user/AddnewUser"));
const AddnewAdmin = lazy(() => import("../pages/user/AddAdmin"));
const TeamList = lazy(() => import("../pages/user/TeamList"));
const AdminList = lazy(() => import("../pages/user/AdminList"));
const SourceList = lazy(() => import("../pages/source/SourceList"));
const AddNewLead = lazy(() => import("../pages/leads/AddNewLead"));
const AddSource = lazy(() => import("../pages/source/AddSource"));
const CourseList = lazy(() => import("../pages/course/CourseList"));
const AddCourse = lazy(() => import("../pages/course/AddCourse"));
const BranchList = lazy(() => import("../pages/branch/BranchList"));
const CityList = lazy(() => import("../pages/city/CityList"));
const AddStatus = lazy(() => import("../pages/status/AddStatus"));
const StatusList = lazy(() => import("../pages/status/StatusList"));
const AddBasicInfo = lazy(() => import("../pages/user/AddBasicInfo"));
const AddBranch = lazy(() => import("../pages/branch/AddBranch"));
const AddCity = lazy(() => import("../pages/city/AddCity"));
const UpdateCourse = lazy(() => import("../pages/course/UpdateCourse"));
const UpdateBranch = lazy(() => import("../pages/branch/UpdateBranch"));
const UpdateSource = lazy(() => import("../pages/source/UpdateSource"));
const UpdateCity = lazy(() => import("../pages/city/UpdateCity"));
const UpdateStatus = lazy(() => import("../pages/status/UpdateStatus"));
const LeadList = lazy(() => import("../pages/leads/LeadList"));
const LogisticsList = lazy(() => import("../pages/Logistics/Logistics"));
const CreateLogistic = lazy(() =>
  import("../pages/Logistics/CreateLogistics/AddConsignmentsForm.tsx")
);
const Pincode = lazy(() => import("../pages/Logistics/Pincode/Pincode "));
const UpdateUser = lazy(() => import("../pages/user/UpdateUser"));
const UserDashboard = lazy(() => import("../pages/dashboard/UserDashboard"));
const SuperDashboard = lazy(() => import("../pages/dashboard/SuperDashboard"));
const AdminDashboard = lazy(() => import("../pages/dashboard/AdminDashboard"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const Support = lazy(() => import("../pages/support/Support"));
const WhatsAppMsg = lazy(() => import("../pages/WhatsApp/Message"));
const WhatsAppImg = lazy(() => import("../pages/WhatsApp/Image"));
const WhatsAppDocs = lazy(() => import("../pages/WhatsApp/Documents"));
const WhatsAppAddMsg = lazy(() => import("../pages/WhatsApp/AddMessage"));
const WhatsAppAddImg = lazy(() => import("../pages/WhatsApp/AddImage"));
const WhatsAppAddDocs = lazy(() => import("../pages/WhatsApp/AddDocuments"));

const IndiaMart = lazy(() => import("../pages/IndiaMart/IndiaMart"));
const AdminDetails = lazy(() => import("../pages/user/AdminDetails"));
const UserDetails = lazy(() => import("../pages/user/UserDetails"));
const Unauthorized = lazy(() => import("../pages/Unauthorized/Unauthorized"));
const SideMenu = lazy(() => import("../pages/SideMenu/SideMenu"));
const UpdateSideMenu = lazy(() => import("../pages/SideMenu/Update"));
const BulkUpload = lazy(() => import("../pages/BulkUpload/BulkUpload"));
const LeadReport = lazy(() => import("../pages/LeadReports/LeadReport"));
const UpdateInvoice = lazy(() =>
  import("../pages/leads/components/UpdateInvoice")
);

const AllRoutes = () => {
  return (
    <Routes>
      {/* add routes */}

      <Route
        path="/"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Dashboard">
              <UserDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<LazyLoader />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/password-set"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Team List">
              <PasswordSet />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/admin-dashboard"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Dashboard">
              <AdminDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/super-dashboard"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Dashboard">
              <SuperDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/profile"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Dashboard">
              <Profile />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/support"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Dashboard">
              <Support />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/sidemenu"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="SideMenu">
              <SideMenu />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/update-sidemenu"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="SideMenu">
              <UpdateSideMenu />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="lead-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <LeadList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="logistic-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Logistic List">
              <LogisticsList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="create-logistic/:awbNo/:UID"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Logistic List">
              <CreateLogistic />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="pincode"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Logistic List">
              <Pincode />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="templates"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WhatsAppMsg />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="whatsapp-image"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WhatsAppImg />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="whatsapp-document"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WhatsAppDocs />
            </PrivateRoute>
          </Suspense>
        }
      />

      <Route
        path="add-message"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WhatsAppAddMsg />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="add-image"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WhatsAppAddImg />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="add-document"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WhatsAppAddDocs />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/addnewadmin"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Admin List">
              <AddnewAdmin />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/addnewuser"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Team List">
              <AddnewUser />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-source"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Source List">
              <AddSource />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-lead"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <AddNewLead />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/upload-lead"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <UploadLead />
            </PrivateRoute>
          </Suspense>
        }
      />
        <Route
        path="/web-lead"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Lead List">
              <WebLead />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-course"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Product List">
              <AddCourse />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-branch"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Branch List">
              <AddBranch />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-basic-information"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute>
              <AddBasicInfo />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-city"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="City List">
              <AddCity />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/add-status"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Status List">
              <AddStatus />
            </PrivateRoute>
          </Suspense>
        }
      />

      {/* list routes  */}
      <Route
        path="/user-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Team List">
              <TeamList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/admin-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Admin List">
              <AdminList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/source-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Source List">
              <SourceList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/product-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Product List">
              <CourseList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/branch-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Branch List">
              <BranchList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/city-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="City List">
              <CityList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/status-list"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Status List">
              <StatusList />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/india-mart"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="India Mart">
              <IndiaMart />
            </PrivateRoute>
          </Suspense>
        }
      />

      <Route
        path="/bulkupload"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="India Mart">
              <BulkUpload />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="/lead-report"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="India Mart">
              <LeadReport />
            </PrivateRoute>
          </Suspense>
        }
      />

      {/* singlepage routes */}
      <Route
        path="admin-details/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Admin List">
              <AdminDetails />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="user-details/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Team List">
              <UserDetails />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="update-course/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Product List">
              <UpdateCourse />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="update-branch/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Branch List">
              <UpdateBranch />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="update-source/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Source List">
              <UpdateSource />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="update-city/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="City List">
              <UpdateCity />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="update-status/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Status List">
              <UpdateStatus />
            </PrivateRoute>
          </Suspense>
        }
      />

      <Route
        path="update-user/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Team List">
              <UpdateUser />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="update-invoice/:id"
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute requiredPermissions="Team List">
              <UpdateInvoice />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="loader"
        element={
          // <Suspense fallback={<LazyLoader/>}>
          <LazyLoader />
          // </Suspense>
        }
      />

      <Route
        path="/unauthorized"
        element={
          <Suspense fallback={<LazyLoader />}>
            <Unauthorized />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
