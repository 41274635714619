import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";

import { useDispatch, useSelector } from "react-redux";
import { postData, getData } from "../../redux/actions/common.action";
import * as XLSX from "xlsx";
import moment from "moment";

interface LeadFormData {
  assignId: string;
}

const initialState: LeadFormData = {
  assignId: "",
};

const UploadLead: React.FC = () => {
  const dispatch: any = useDispatch();
  const { users, loading, signinuser } = useSelector(
    (state: any) => state.common
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }, 
    watch,
  } = useForm<LeadFormData>();

  const [excelFile, setExcelFile] = useState<File | null>(null);

  const endpoint: string = "lead/addLeads";
  const Navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    // Fetch users' data when the component mounts
    dispatch(getData("user"));
  }, [dispatch]);

  const parseExcelFile = async (file: File, assignId: string) => {
    return new Promise<Array<any>>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const binaryString = event.target?.result as string;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Specify the type as an array of arrays of strings
          const data: string[][] = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
          });

          // Assuming the first row contains column headers
          const columns: string[] = data[0];

          // Check if all required columns are present in the specified sequence
          const requiredColumns = [
            "Customer Name",
            "Phone No",
            "Age",
            "Email Id",
            "Location",
          ];

          const hasAllColumns = requiredColumns.every(
            (column, index) => columns[index] === column
          );

          if (!hasAllColumns) {
            // Reject the promise with an error message
            reject({
              type: "MissingColumns",
              message: `Data format is incorrect. Please refer the sample.`,
            });
            return;
          }

          // Convert the data to the specified format
          const parsedData = data.slice(1).map((row: any, index: number) => {
            const rowData: { [key: string]: any } = {
              CustomerName: row[0],
              MobileNo: row[1],
              Age: row[2],
              Email: row[3],
              Location: row[4],
              assignId: assignId,
            };
            return rowData;
          });

          resolve(parsedData);
        } catch (error) {
          // Reject the promise with the parsing error
          reject({
            type: "ParsingError",
            message: "Error parsing Excel file. Please check the data format.",
          });
        }
      };
      reader.onerror = (error) => reject(error);

      reader.readAsBinaryString(file);
    });
  };



  const onSubmit = async (formData: LeadFormData) => {
    if (!excelFile || !formData.assignId) {
      return;
    }

    try {
      const parsedData = await parseExcelFile(excelFile, formData.assignId);

      console.log("parsedData",parsedData);

      dispatch(
        postData({ myAddLeads: parsedData }, endpoint, Navigate, toast)
      ).then(() => {
        console.log("done success:");

        setExcelFile(null);
        setValue("assignId", initialState.assignId);
        Navigate("/lead-list");
      });
    } catch (error: any) {
      console.log("failed,no success:");
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setExcelFile(file);
  };
console.log("fardeen1",users)
  const useroptions = users?.map((user: any) => {
    if (user.UserType === 3) {
      return (
        <option key={user._id} value={user._id}>
          {user.UserType === 3
            ? signinuser.userId === user._id
              ? "Self"
              : user.Name
            : null}
        </option>
      );
    }
    return null;
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "43px" }}>
      {/* ... Other form elements ... */}
      <Box display={"flex"} mb={9}>
        <Button
          onClick={() => {
            const sampleFilePath =
              process.env.PUBLIC_URL + "/sample/sampleFormat.xlsx";
            const link = document.createElement("a");
            link.href = sampleFilePath;
            link.setAttribute("download", "sampleFormat.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
          size="sm"
          _hover={{ bg: "#FF9000" }}
          bg="#2AA90A"
          borderRadius="full"
          color="whiteAlpha.900"
          mx={3}
        >
          Download Sample
        </Button>
      </Box>

      {/* File Upload Section */}
      <Box display={"flex"} mb={5}>
        <FormControl variant="floating" id="assignId" mx={2}>
          <Select
            autoComplete="off"
            {...register("assignId", {
              required: "AssignTo is required",
            })}
            size="sm"
            borderColor={errors?.assignId ? "red.500" : "gray.400"}
            borderRadius={"0.25rem"}
            defaultValue=""
          >
            <option value="" disabled>
              Select Assigned To
            </option>
            {useroptions}
          </Select>
          <FormLabel fontWeight={"400"} color={"gray.600"}>
            Assigned To
          </FormLabel>
          {errors.assignId && (
            <Text color="red.500" fontSize="xs">
              Assigned To is required
            </Text>
          )}
        </FormControl>
        <FormControl variant="floating" mx={2}>
          <Input
            type="file"
            size="sm"
            onChange={handleFileUpload}
            accept=".xlsx, .xls"
          />
          <FormLabel fontWeight={"400"} color={"gray.600"}>
            Upload Excel File
          </FormLabel>
          {excelFile && (
            <Text color="black" fontSize="sm" mt="1">
              File: {excelFile.name}
            </Text>
          )}
        </FormControl>
      </Box>

      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <Button
          isLoading={loading}
          loadingText="Please wait..."
          type="submit"
          size={"sm"}
          _hover={{ bg: "#FF9000" }}
          bg={
            loading
              ? "gray.400"
              : excelFile && watch("assignId")
              ? "#FF9000"
              : "gray.400"
          }
          borderRadius={"0.25rem"}
          color={loading ? "whiteAlpha.900" : "whiteAlpha.900"} // Adjusted color based on loading state
          fontWeight={"700"}
          fontSize={"1rem"}
          isDisabled={loading || !excelFile || !watch("assignId")}
        >
          Submit
        </Button>
      </Box>

      <DevTool control={control} />
    </form>
  );
};

export default UploadLead;
