import React, { ReactNode } from "react";

import LoginRoute from "../pages/login/LoginRoute";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

// interface AuthState {
//   auth: boolean;
// }

interface PrivateRouteProps {
  children: ReactNode;
  requiredPermissions: string;
}

const PrivateRoute = ({ children, requiredPermissions }: PrivateRouteProps) => {
  // const { auth } = useSelector((state: { common: AuthState }) => state.common);
  const token = Cookies.get("token");

  interface SigninUser {
    signinuser: {
      UserType: number;
      menuPermissions: [string];
    };
  }
  const { signinuser } = useSelector(
    (state: { common: SigninUser }) => state.common
  );

  // Extract the permissions from the signinuser object
  const { UserType, menuPermissions } = signinuser || {};

  if (!token) {
    console.log("auth please");
    return (
      <>
        <LoginRoute />
      </>
    );
  } else {
    if (UserType === 1) {
      return children;
    } else if (menuPermissions?.includes(requiredPermissions)) {
      return children;
    } else {
      return (
        <>
          <Navigate to="/unauthorized" />
        </>
      );
    }
  }
};

export default PrivateRoute;
