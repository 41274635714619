//POST TYPES
export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_COURSE_DATA_SUCCESS = "POST_COURSE_DATA_SUCCESS";
export const POST_BRANCH_DATA_SUCCESS = "POST_BRANCH_DATA_SUCCESS";
export const POST_SOURCE_DATA_SUCCESS = "POST_SOURCE_DATA_SUCCESS";
export const POST_CITY_DATA_SUCCESS = "POST_CITY_DATA_SUCCESS";
export const POST_WHATSIMAGE_DATA_SUCCESS = "POST_WHATSIMAGE_DATA_SUCCESS";
export const POST_STATUS_DATA_SUCCESS = "POST_STATUS_DATA_SUCCESS";
export const POST_USER_DATA_SUCCESS = "POST_USER_DATA_SUCCESS";
export const POST_SUPPORT_DATA_SUCCESS = "POST_SUPPORT_DATA_SUCCESS";
export const POST_ADMIN_DATA_SUCCESS = "POST_ADMIN_DATA_SUCCESS";
export const POST_LEAD_DATA_SUCCESS = "POST_LEAD_DATA_SUCCESS";
export const POST_LEAD_ASSIGN_SUCCESS = "POST_LEAD_ASSIGN_SUCCESS";
export const POST_SIGN_IN_SUCCESS = "POST_SIGN_IN_SUCCESS";
export const POST_SIGN_OUT_SUCCESS = "POST_SIGN_OUT_SUCCESS";
export const POST_LEAD_LOGS_SUCCESS = "POST_LEAD_LOGS_SUCCESS";
export const POST_SIDE_MENU_SUCCESS = "POST_SIDE_MENU_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

//GET TYPES
export const GET_DATA_REQUEST = "GET_DATA_REQUEST";
export const GET_BRANCH_DATA_SUCCESS = "GET_BRANCH_DATA_SUCCESS";
export const GET_COURSE_DATA_SUCCESS = "GET_COURSE_DATA_SUCCESS";
export const GET_SOURCE_DATA_SUCCESS = "GET_SOURCE_DATA_SUCCESS";
export const GET_CITY_DATA_SUCCESS = "GET_CITY_DATA_SUCCESS";
export const GET_WHATSIMAGE_DATA_SUCCESS = "GET_WHATSIMAGE_DATA_SUCCESS";
export const GET_CONTACT_DATA_SUCCESS = "GET_CONTACT_DATA_SUCCESS";
export const GET_STATUS_DATA_SUCCESS = "GET_STATUS_DATA_SUCCESS";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_SUPPORT_DATA_SUCCESS = "GET_SUPPORT_DATA_SUCCESS";
export const GET_ADMIN_DATA_SUCCESS = "GET_ADMIN_DATA_SUCCESS";
export const GET_LEAD_DATA_SUCCESS = "GET_LEAD_DATA_SUCCESS";
export const GET_LEAD_COUNT_SUCCESS = "GET_LEAD_COUNT_SUCCESS";
export const GET_COURSE_ANALYTICS_SUCCESS = "GET_COURSE_ANALYTICS_SUCCESS";
export const GET_SOURCE_ANALYTICS_SUCCESS = "GET_SOURCE_ANALYTICS_SUCCESS";
export const GET_SIDE_MENU_SUCCESS = 'GET_SIDE_MENU_SUCCESS'
export const GET_DATA_FAILURE = "GET_DATA_FAILURE";

//GET SINGLEDATA TYPES
export const GET_SINGLE_DATA_REQUEST = "GET_SINGLE_DATA_REQUEST";
export const GET_SINGLE_BRANCH_DATA_SUCCESS = "GET_SINGLE_BRANCH_DATA_SUCCESS";
export const GET_SINGLE_COURSE_DATA_SUCCESS = "GET_SINGLE_COURSE_DATA_SUCCESS";
export const GET_SINGLE_SOURCE_DATA_SUCCESS = "GET_SINGLE_SOURCE_DATA_SUCCESS";
export const GET_SINGLE_CITY_DATA_SUCCESS = "GET_SINGLE_CITY_DATA_SUCCESS";
export const GET_SINGLE_WHATSIMAGE_DATA_SUCCESS = "GET_SINGLE_WHATSIMAGE_DATA_SUCCESS";
export const GET_SINGLE_CONTACT_DATA_SUCCESS =
  "GET_SINGLE_CONTACT_DATA_SUCCESS";
export const GET_SINGLE_STATUS_DATA_SUCCESS = "GET_SINGLE_STATUS_DATA_SUCCESS";
export const GET_SINGLE_USER_DATA_SUCCESS = "GET_SINGLE_USER_DATA_SUCCESS";
export const GET_SINGLE_SUPPORT_DATA_SUCCESS = "GET_SINGLE_USER_DATA_SUCCESS";
export const GET_SINGLE_ADMIN_DATA_SUCCESS = "GET_SINGLE_USER_DATA_SUCCESS";
export const GET_SINGLE_LEAD_DATA_SUCCESS = "GET_SINGLE_LEAD_DATA_SUCCESS";
export const GET_SINGLE_SIDE_MENU_SUCCESS = "GET_SINGLE_SIDE_MENU_SUCCESS";
export const GET_SINGLE_DATA_FAILURE = "GET_SINGLE_DATA_FAILURE";

// DELETE TYPES
export const DELETE_DATA_REQUEST = "DELETE_DATA_REQUEST";
export const DELETE_BRANCH_DATA_SUCCESS = "DELETE_BRANCH_DATA_SUCCESS";
export const DELETE_COURSE_DATA_SUCCESS = "DELETE_COURSE_DATA_SUCCESS";
export const DELETE_SOURCE_DATA_SUCCESS = "DELETE_SOURCE_DATA_SUCCESS";
export const DELETE_CITY_DATA_SUCCESS = "DELETE_CITY_DATA_SUCCESS";
export const DELETE_WHATSIMAGE_DATA_SUCCESS = "DELETE_WHATSIMAGE_DATA_SUCCESS";
export const DELETE_STATUS_DATA_SUCCESS = "DELETE_STATUS_DATA_SUCCESS";
export const DELETE_USER_DATA_SUCCESS = "DELETE_USER_DATA_SUCCESS";
export const DELETE_ADMIN_DATA_SUCCESS = "DELETE_USER_DATA_SUCCESS";
export const DELETE_LEAD_DATA_SUCCESS = "DELETE_LEAD_DATA_SUCCESS";
export const DELETE_SIDE_MENU_SUCCESS = "DELETE_SIDE_MENU_SUCCESS";
export const DELETE_DATA_FAILURE = "DELETE_DATA_FAILURE";

// UPDATE TYPES
export const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST";
export const UPDATE_BRANCH_DATA_SUCCESS = "UPDATE_BRANCH_DATA_SUCCESS";
export const UPDATE_COURSE_DATA_SUCCESS = "UPDATE_COURSE_DATA_SUCCESS";
export const UPDATE_SOURCE_DATA_SUCCESS = "UPDATE_SOURCE_DATA_SUCCESS";
export const UPDATE_CITY_DATA_SUCCESS = "UPDATE_CITY_DATA_SUCCESS";
export const UPDATE_WHATSIMAGE_DATA_SUCCESS = "UPDATE_WHATSIMAGE_DATA_SUCCESS";
export const UPDATE_STATUS_DATA_SUCCESS = "UPDATE_STATUS_DATA_SUCCESS";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_ADMIN_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_LEAD_DATA_SUCCESS = "UPDATE_LEAD_DATA_SUCCESS";
export const UPDATE_LOGCOURSE_DATA_SUCCESS = "UPDATE_LOGCOURSE_DATA_SUCCESS";
export const UPDATE_SIDE_MENU_SUCCESS = "UPDATE_SIDE_MENU_SUCCESS";
export const UPDATE_DATA_FAILURE = "UPDATE_DATA_FAILURE";
