import Cookies from "js-cookie";

export const initialStates: {
  course: Record<string, any>;
  user: Record<string, any>;
  support: Record<string, any>;
  admin: Record<string, any>;
  branch: Record<string, any>;
  source: Record<string, any>;
  city: Record<string, any>;
  whatsapp: Record<string, any>;
  contact: Record<string, any>;
  status: Record<string, any>;
  lead: Record<string, any>;
  assignlead: Record<string, any>;
  logs: Record<string, any>;
  leadscount: any[];
  signinuser: Record<string, any> | null;
  courses: any[];
  branches: any[];
  sources: any[];
  cities: any[];
  whatsapps : any[];
  statuses: any[];
  users: any[];
  supports: any[];
  admins: any[];
  leads: any[];
  assignleads: any[];
  courseanalytics: any[];
  sourceanalytics: any[];
  success: boolean;
  loading: boolean;
  error: boolean;
  // auth: boolean;
} = {
  course: {},
  user: {},
  support: {},
  admin: {},
  branch: {},
  source: {},
  city: {},
  whatsapp: {},
  contact: {},
  status: {},
  lead: [],
  assignlead:[],
  logs: [],
  signinuser: JSON.parse(Cookies.get("signinuser") || "null"),
  courses: [],
  leadscount: [],
  branches: [],
  sources: [],
  cities: [],
  whatsapps: [],
  statuses: [],
  users: [],
  supports: [],
  admins: [],
  leads: [],
  assignleads:[],
  courseanalytics: [],
  sourceanalytics: [],
  success: false,
  loading: false,
  error: false,
  // auth: JSON.parse(Cookies.get("auth") || "false"),
};
