import { Box, Stack } from "@chakra-ui/react";
import Sidebar from "./components/Sidebar";
import { useState } from "react";
import AllRoutes from "./routes/AllRoutes";
import { useEffect } from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import { useLocation } from "react-router-dom";
import LoginRoute from "./pages/login/LoginRoute";
import Cookies from "js-cookie";
import PasswordSetRoute from "./pages/login/PasswordSetRoute";

function App() {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const location = useLocation();
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // const { auth } = useSelector((state:any) => state.common);
  const token = Cookies.get("token");

  return (
    <Stack h="100%">
      {token ? (
        <Box display="flex" h="100%">
          {/* Sidebar */}
          <Sidebar
            isCollapsed={isCollapsed}
            handleToggleCollapse={handleToggleCollapse}
          />

          {/* Content */}
          <Box
            flexGrow={1}
            minWidth={0}
            flexShrink={1}
            ml={isCollapsed ? "80px" : "200px"}
          >
            <Box minH="100vh" bg="gray.200">
              <Navbar
                isCollapsed={isCollapsed}
                handleToggleCollapse={handleToggleCollapse}
              />
              <Box
                minH="85vh"
                borderRadius="md"
                m={2}
                bg={location.pathname === "/" ? "" : "whiteAlpha.900"}
              >
                <AllRoutes />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <>{<LoginRoute />}</>
      )}
    </Stack>
  );
}

export default App;
