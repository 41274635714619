import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    RadioGroup,
    Select,
    SimpleGrid,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Radio,
    Td,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
    Checkbox, 
    FormControl,
    FormLabel,
    Toast,
  } from "@chakra-ui/react";

  import { useForm } from "react-hook-form";

  
  import "react-date-range/dist/styles.css";
  import "react-date-range/dist/theme/default.css";
  import React, { useState, useEffect, useMemo, useRef } from "react";
  
  import { useDispatch, useSelector } from "react-redux";
  import {
    deleteData,
    getData,
    getDataById,
    postData,
  } from "../../redux/actions/common.action";
  import Loader from "../../components/Loader";
  import { SearchIcon } from "@chakra-ui/icons";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";
  import { FaSortAlphaDownAlt, FaSortAlphaUp } from "react-icons/fa";
 
  import { BASE_URL } from "../../config/RequestMethod";
  import Cookies from "js-cookie";

const AssignLead = () => {
    const { users,signinuser } = useSelector((state: any) => state.common);
     const [selectedLeads,setSelectedLeads]=useState<any[]>([])
    const dispatch: any = useDispatch();
    const Navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState<string>("");
    console.log(search);
    const [limit, setLimit] = useState<number>(10);
    const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
    const [sortColumn, setSortColumn] = useState("");
    console.log(sortColumn);
  
    const [followUpStart, setfollowUpStart] = useState([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
    const [enquiryDate, setEnqDate] = useState([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  
  interface Option {
    value: string;
    label: string;
  }

  
interface LeadFormData {
    assignId: string;
  }
  

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<LeadFormData>();



  function debounce(func: Function, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  }

  //Fetching the Lead data
  const handleFilterSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const token = Cookies.get("token"); // Get the token from the cookie
      const url = `${BASE_URL}lead/noAuth?limit=${limit}&page=${page}&keyword=${search}`;

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the headers
        },
      });

      setFilteredData(res.data.Data);
      setOriginalData(res.data.Data);
      setTotalPages(res.data.totalPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // Debounce the handleFilterSubmit function by 1 second
  const debouncedHandleFilterSubmit = debounce(handleFilterSubmit, 1000);
  useEffect(() => {
    debouncedHandleFilterSubmit();
  }, [page, limit, followUpStart, enquiryDate]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    const mySearch = event.target.value;

    const lowercasedSearch = mySearch.toLowerCase();

    // Modify the filter based on the search input and sort order
    const filteredData = originalData.filter((item: any) => {
      return (
        item.name?.toLowerCase().includes(lowercasedSearch) ||
        item.Email?.toLowerCase().includes(lowercasedSearch) ||
        item.MobileNo?.includes(lowercasedSearch)
      );
    });
    console.log("filtersearch-----", filteredData);
    setFilteredData(filteredData);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setPage(pageNumber);
  };
  const endpoint: string = "lead/assign";
  const toast = useToast();

  //sorting for the table
  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortOrder("asc");
      setSortColumn(column);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("full");
  const [activeTab, setActiveTab] = useState("All");
  const sortedData = useMemo(() => {
    if (sortColumn === "") {
      return activeTab === "Today"
        ? filteredData.filter(
            (el: any) =>
              new Date(el.enquiryDate).toLocaleDateString() ===
              new Date().toLocaleDateString()
          )
        : filteredData;
    }

    return [...filteredData].sort((a, b) => {
      const valueA = a[sortColumn] as string;
      const valueB = b[sortColumn] as string;

      if (sortOrder === "asc") {
        return valueA?.localeCompare(valueB);
      } else {
        return valueB?.localeCompare(valueA);
      }
    });
  }, [filteredData, sortColumn, sortOrder, activeTab]);

  const handleSizeClick = (newSize: any) => {
    setSize(newSize);
    onOpen();
  };

//   console.log("----sor", sortedData);
  const { loading, lead } = useSelector(
    (state: any) => state.common
  );
//   console.log("leads----", lead);



  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  
  useEffect(() => {
    // Fetch users' data when the component mounts
    dispatch(getData("user"));
  }, [dispatch]);

  
  const useroptions = users?.map((user: any) => {
      return (
        <option key={user._id} value={user._id}>
          {user.Name}
        </option>
      );
  });

  const handleCheck=(e:any,lead:any)=>{
  if(e.target.checked){
        setSelectedLeads([
            ...selectedLeads,lead
        ])
  }else if(!e.target.checked){
    const leads=selectedLeads.filter((item)=>{
        return item._id!=lead._id;
    })
    
    setSelectedLeads(leads)
  }

  }

  const initialState: LeadFormData = {
    assignId: "",
  };

  const onSubmit = async (formData: LeadFormData) => {
    if (!selectedLeads || !formData.assignId) {
      return;
    }

    try {

    const addSelectedLeads=selectedLeads.map((item)=>{
        return {
            CustomerName:item.CustomerName,
            MobileNo:item.MobileNo,
            Email:item.Email,
            Age:item.Age,
            ProductName:item.ProductName,
            ProductPrice:item.ProductPrice,
            Location:item.Location,
            assignId:formData.assignId
        }
    })

    const leadIds=selectedLeads.map((item)=>{
        return item._id
    })

      dispatch(
        postData({ myAddLeads: addSelectedLeads,leadIds:leadIds }, endpoint, Navigate, toast)
      ).then(() => {
        console.log("done success:");

        setValue("assignId", initialState.assignId);

        Navigate("/lead-list");
      });
    } catch (error: any) {
      console.log("failed,no success:");
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };


  return (
      <Stack py="1" bg="whiteAlpha.900" borderRadius={"0.25rem"}>
         <form 
         onSubmit={handleSubmit(onSubmit)} 
         style={{ padding: "43px" }}>
      {/* File Upload Section */}
      <Box display={"flex"} justifyContent={"space-between"} >
        <FormControl style={{width:"50vw"}}  variant="floating" id="assignId" mx={2}>
          <Select
            autoComplete="off"
            {...register("assignId", {
              required: "AssignTo is required",
            })}
            size="sm"
            borderColor={errors?.assignId ? "red.500" : "gray.400"}
            borderRadius={"0.25rem"}
            defaultValue=""
          >
            <option value="" disabled>
              Select Assigned To
            </option>
            {useroptions}
          </Select>
          <FormLabel fontWeight={"400"} color={"gray.600"}>
            Assigned To
          </FormLabel>
          {errors.assignId && (
            <Text color="red.500" fontSize="xs">
              Assigned To is required
            </Text>
          )}
        </FormControl>

        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <Button
          isLoading={loading}
          loadingText="Please wait..."
          type="submit"
          size={"sm"}
          _hover={{ bg: "#FF9000" }}
          bg={
            loading
              ? "gray.400"
              : selectedLeads && watch("assignId")
              ? "#FF9000"
              : "gray.400"
          }
          borderRadius={"0.25rem"}
          color={loading ? "whiteAlpha.900" : "whiteAlpha.900"} // Adjusted color based on loading state
          fontWeight={"700"}
          fontSize={"1rem"}
        //   isDisabled={loading || !excelFile || !watch("assignId")}
        >
          Submit
        </Button>
      </Box>
      </Box>



      {/* <DevTool control={control} /> */}
    </form>
        <Box
          px="4"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Select
              borderRadius={"0.25rem"}
              borderColor={"gray.400"}
              size="xs"
              value={limit}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setLimit(parseInt(e.target.value, 10))
              }
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
          </Box>

          <Box>
            <Button
              borderRadius={"0.25rem"}
              borderColor={"gray.400"}
              size="xs"
              variant={activeTab === "Today" ? "solid" : "outline"}
              mx={2}
              onClick={() => handleTabClick("Today")}
            >
              Today
            </Button>
            <Button
              borderRadius={"0.25rem"}
              borderColor={"gray.400"}
              size="xs"
              variant={activeTab === "All" ? "solid" : "outline"}
              onClick={() => handleTabClick("All")}
            >
              All
            </Button>
          </Box>

          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" mb="4" />
              </InputLeftElement>
              <Input
                w="400px"
                value={search}
                onChange={handleSearchChange}
                borderRadius={"0.25rem"}
                borderColor="gray.400"
                size="xs"
                type="text"
                placeholder="Name, Email, or Mobile No."
              />
            </InputGroup>
          </Box>
        </Box>
        <Box overflowX="auto" className="shrinkable-table">
          <TableContainer  className="tarun" whiteSpace={"nowrap"}>
            <Box overflowY="auto" height={480} maxHeight="480px">
              <Table  size="sm">
                <Thead
                  bg={"blue.600"}
                  color={"whiteAlpha.900"}
                  position={"sticky"}
                  borderRadius={"20px"}
                  top="0"
                  zIndex={1}
             
                >
                  <Tr>
                  <Th
                      onClick={() => handleSort("SID")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Select </span>            
                      </Text>
                    </Th>
                    <Th
                      onClick={() => handleSort("SID")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> SID </span>
                        <span>
                          {sortColumn === "SID" && sortOrder === "asc" && (
                            <FaSortAlphaUp
                              style={{ marginTop: "1px", cursor: "pointer" }}
                              size={13}
                            />
                          )}
                          {sortColumn === "SID" && sortOrder === "desc" && (
                            <FaSortAlphaDownAlt
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                          {sortColumn !== "SID" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>
                    <Th
                      onClick={() => handleSort("CustomerName")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Name</span>
                        <span>
                          {sortColumn === "CustomerName" &&
                            sortOrder === "asc" && (
                              <FaSortAlphaUp
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                                size={13}
                              />
                            )}
                          {sortColumn === "CustomerName" &&
                            sortOrder === "desc" && (
                              <FaSortAlphaDownAlt
                                size={13}
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          {sortColumn !== "CustomerName" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>
                    <Th
                      onClick={() => handleSort("Email")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Email</span>
                        <span>
                          {sortColumn === "Email" && sortOrder === "asc" && (
                            <FaSortAlphaUp
                              style={{ marginTop: "1px", cursor: "pointer" }}
                              size={13}
                            />
                          )}
                          {sortColumn === "Email" && sortOrder === "desc" && (
                            <FaSortAlphaDownAlt
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                          {sortColumn !== "Email" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>
                    <Th
                      onClick={() => handleSort("MobileNo")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Mob 1</span>
                        <span>
                          {sortColumn === "MobileNo" &&
                            sortOrder === "asc" && (
                              <FaSortAlphaUp
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                                size={13}
                              />
                            )}
                          {sortColumn === "MobileNo" &&
                            sortOrder === "desc" && (
                              <FaSortAlphaDownAlt
                                size={13}
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          {sortColumn !== "MobileNo" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>
                    <Th
                      onClick={() => handleSort("ProductName")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Product</span>
                        <span>
                          {sortColumn === "ProductName" &&
                            sortOrder === "asc" && (
                              <FaSortAlphaUp
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                                size={13}
                              />
                            )}
                          {sortColumn === "ProductName" &&
                            sortOrder === "desc" && (
                              <FaSortAlphaDownAlt
                                size={13}
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          {sortColumn !== "ProductName" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>
                    <Th
                      onClick={() => handleSort("ProductPrice")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Price</span>
                        <span>
                          {sortColumn === "ProductPrice" &&
                            sortOrder === "asc" && (
                              <FaSortAlphaUp
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                                size={13}
                              />
                            )}
                          {sortColumn === "ProductPrice" &&
                            sortOrder === "desc" && (
                              <FaSortAlphaDownAlt
                                size={13}
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          {sortColumn !== "ProductPrice" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>

                    <Th
                      onClick={() => handleSort("enquiryDate")}
                      padding={2}
                      w={15}
                      fontSize={"0.65rem"}
                      color={"whiteAlpha.900"}
                    >
                      <Text display="flex" justifyContent="start" gap="1">
                        {" "}
                        <span> Enq. Date </span>
                        <span>
                          {sortColumn === "enquiryDate" &&
                            sortOrder === "asc" && (
                              <FaSortAlphaUp
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                                size={13}
                              />
                            )}
                          {sortColumn === "enquiryDate" &&
                            sortOrder === "desc" && (
                              <FaSortAlphaDownAlt
                                size={13}
                                style={{
                                  marginTop: "1px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          {sortColumn !== "enquiryDate" && (
                            <FaSortAlphaUp
                              size={13}
                              style={{ marginTop: "1px", cursor: "pointer" }}
                            />
                          )}
                        </span>
                      </Text>
                    </Th>

                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading ? (
                    <Loader />
                  ) : filteredData?.length === 0 ? (
                    <Tr>
                      <Td
                        color="whiteAlpha.900"
                        bg="red.500"
                        colSpan={14}
                        textAlign="center"
                        fontWeight={700}
                      >
                        No Data Found
                      </Td>
                    </Tr>
                  ) : (
                    sortedData?.map((el: any, index: any) => (
                      <Tr
                        key={el._id}
                        fontSize={"0.5rem"}
                        style={{ position: "relative" }}
                        // bg={
                        //   el.LogType && el.LogType.length > 0
                        //     ? el.LogType[el.LogType.length - 1] === "Cancel"
                        //       ? "red.100"
                        //       : el.LogType[el.LogType.length - 1] ===
                        //         "Pending"
                        //       ? "yellow.100"
                        //       : el.LogType[el.LogType.length - 1] === "Verify"
                        //       ? "green.100"
                        //       : "white"
                        //     : ""
                        // }
                      >
                           <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                         <Checkbox name="el.CustomerName" onChange={(e)=>{handleCheck(e,el)}}/>
                        </Td>
                      
                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                          {el.SID < 10
                            ? `000${el.SID}`
                            : el.SID < 100
                            ? `00${el.SID}`
                            : el.SID < 1000
                            ? `0${el.SID}`
                            : el?.SID?.toString()}
                        </Td>
                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                          {el.CustomerName}
                        </Td>
                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                          {el.Email}
                        </Td>
                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                          {el.MobileNo}
                        </Td>
                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                        >
                          {el?.ProductName}
                        </Td>

                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                          {el.ProductPrice}
                        </Td>
                

                        <Td
                          fontWeight="500"
                          fontSize={"0.8rem"}
                          padding={2}
                          w={15}
                        >
                          {new Date(el.enquiryDate).toLocaleDateString()}
                        </Td>

                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </Box>
          </TableContainer>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          gap={"3"}
          px={3}
          borderTop={"1px gray.500"}
        >
          <Button
            size={"xs"}
            onClick={() => handlePageChange(page - 1)}
            colorScheme="gray"
            disabled={page === 1}
            borderRadius={"50%"}
            p={"0.5rem 0"}
          >
            &lt;
          </Button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => {
              if (
                pageNumber <= 3 ||
                (pageNumber >= page - 1 && pageNumber <= page + 1) ||
                pageNumber > totalPages - 3
              ) {
                return (
                  <Button
                    size={"xs"}
                    borderRadius={"50%"}
                    p={"0.5rem 0"}
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={page === pageNumber}
                    colorScheme={page === pageNumber ? "orange" : "gray"}
                  >
                    {pageNumber}
                  </Button>
                );
              } else if (pageNumber === 4 || pageNumber === totalPages - 2) {
                return <Text key={pageNumber}>...</Text>;
              } else {
                return null;
              }
            }
          )}

          <Button
            size={"xs"}
            borderRadius={"50%"}
            p={"0.5rem 0"}
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            &gt;
          </Button>
        </Box>
      </Stack>)
}

export default AssignLead;